<!--
 * @Description: 请求分析
-->
<template>
  <page-header-wrapper>
    <div class="submit_application_wrapper">
      <BasicInfo :info="originData"></BasicInfo>
      <div class="mt-10"></div>
      <DataList :info="originData">
        <div class="footer_btn mt-10">
          <a-button class="mr-10" @click="handleBack">取消</a-button>
          <a-button type="primary" @click="handleSubmit">确认</a-button>
        </div>
      </DataList>
    </div>
  </page-header-wrapper>
</template>

<script>
import BasicInfo from './components/BasicInfo.vue'
import DataList from './components/DataList.vue'
import ReportController from '../api/report'
import { submitAnalysisApplication } from '../api/functionApi'

export default {
  name: 'SubmitApplication',
  components: {
    BasicInfo,
    DataList
  },
  data() {
    return {
      // 初始数据，不是表单，可以不列举每一个key
      originData: {}
    }
  },
  computed: {},
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const params = { id: this.$route.params.id }
      const data = await ReportController.getItem(params)
      this.originData = data || {}
    },
    handleBack() {
      this.$router.go(-1)
    },
    handleSubmit() {
      const _action = async () => {
        try {
          await submitAnalysisApplication({ id: this.$route.params.id })
          this.$info({
            content: '提交分析请求成功!'
          })
          this.$router.go(-1)
        } catch (error) {
          console.log(error)
        }
      }

      this.$confirm({
        type: 'warning',
        title: `确认该楼、面、侧线、点需要专家分析需求，
                确认后无法新增楼、面、侧线、点和修改上传数据
                请谨慎操作`,
        onOk: _action
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.submit_application_wrapper {
  ::v-deep .ant-card-head-title {
    font-weight: bold;
  }
}

.footer_btn {
  text-align: right;
}
</style>
