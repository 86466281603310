var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page-header-wrapper", [
    _c(
      "div",
      { staticClass: "submit_application_wrapper" },
      [
        _c("BasicInfo", { attrs: { info: _vm.originData } }),
        _c("div", { staticClass: "mt-10" }),
        _c("DataList", { attrs: { info: _vm.originData } }, [
          _c(
            "div",
            { staticClass: "footer_btn mt-10" },
            [
              _c(
                "a-button",
                { staticClass: "mr-10", on: { click: _vm.handleBack } },
                [_vm._v("取消")]
              ),
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }